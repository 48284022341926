import React from "react";
// Components
import Layout from "components/Layout";
import Seo from "components/Seo";

export default function Page404() {
  return (
    <Layout>
      <Seo title="404: Página não encontrada" />
      <h2>Página não encontrada</h2>
    </Layout>
  );
}
